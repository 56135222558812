.App {
  text-align: center;
  min-height: 100vh;
}
#root {
  min-height: 100vh;
}
* {
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: darkgray;
}
main {
  margin-bottom: 220px;
}
.footer-bs {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 10rem;
  color: white;
}
.home {
  padding-top: 2rem;
  padding-bottom: 10rem;
  max-width: 100vw;
  margin-right: 0;
  padding-left: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.description {
  font-size: 2rem;
  white-space: pre-line;
}

.techno {
  background-color: darkgray;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #45a29e;
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
  width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
}

textarea {
  width: 100%;
  min-height: 100px; /* Optional: Set a minimum height */
  padding: 10px;
  box-sizing: border-box; /* Ensure padding is included in the element's width/height */
}

.scroll-to-top {
  position: fixed;
  bottom: 12rem;
  right: 2rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5rem;
}

.scroll-to-top:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .description {
    font-size: 1.5rem;
    white-space: pre-line;
  }
}
